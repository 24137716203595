var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom_invoice"},[_c('h2',{staticClass:"shipment_number text-right"},[_vm._v(" Invoice No - "+_vm._s(_vm.$route.params.invoice_no)+" ")]),_c('div',{staticStyle:{"background-color":"#fffef8","border-radius":"10px"}},[_c('b-container',[_c('b-row',{staticClass:"pl-2 pr-2 pt-3"},[_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Invoice no*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Invoice no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Invoice number"},model:{value:(_vm.form.invoice_no),callback:function ($$v) {_vm.$set(_vm.form, "invoice_no", $$v)},expression:"form.invoice_no"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"AWB*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"AWB","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter AWB number"},model:{value:(_vm.form.awb),callback:function ($$v) {_vm.$set(_vm.form, "awb", $$v)},expression:"form.awb"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Flight*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Flight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Flight "},model:{value:(_vm.form.flight),callback:function ($$v) {_vm.$set(_vm.form, "flight", $$v)},expression:"form.flight"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"pt-2",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"ETA*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"ETA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"placeholder":"Select Date"},model:{value:(_vm.form.eta),callback:function ($$v) {_vm.$set(_vm.form, "eta", $$v)},expression:"form.eta"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('br')],1)],1),_c('div',{staticClass:"mt-3",staticStyle:{"background-color":"#f3f3f3","border-radius":"10px"}},[_c('b-container',[_c('b-row',{staticClass:"pl-2 pr-2 pt-2"},[_c('b-col',[_c('span',{staticClass:"edit_form_header"},[_vm._v("Seafood type")])]),_c('b-col',[_c('span',{staticClass:"edit_form_header"},[_vm._v("Quality")])]),_c('b-col',[_c('span',{staticClass:"edit_form_header"},[_vm._v("Grading (Kg)")])]),_c('b-col',[_c('span',{staticClass:"edit_form_header"},[_vm._v("Weight (Kg)")])]),_c('b-col',[_c('span',{staticClass:"edit_form_header"},[_vm._v("Rate per Kg ($)")])]),_c('b-col',[_c('span',{staticClass:"edit_form_header"},[_vm._v("Total cost ($)")])])],1),_vm._l((_vm.form.boxes.seafoods),function(seafood){return _c('b-row',{key:seafood.seafoodtype,staticClass:"pl-2 pr-2 pt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Seafood Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input_background",attrs:{"placeholder":"Enter Business name"},model:{value:(seafood.seafoodtype),callback:function ($$v) {_vm.$set(seafood, "seafoodtype", $$v)},expression:"seafood.seafoodtype"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Quality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input_background",attrs:{"placeholder":"Enter Quality"},model:{value:(seafood.quality),callback:function ($$v) {_vm.$set(seafood, "quality", $$v)},expression:"seafood.quality"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-class":"form_label_class"}},[_c('validation-Provider',{staticClass:"input_background",attrs:{"name":"Grading (Kg)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input_background",attrs:{"placeholder":"Enter Grading"},model:{value:(seafood.grading),callback:function ($$v) {_vm.$set(seafood, "grading", $$v)},expression:"seafood.grading"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Weight (Kg)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input_background",attrs:{"placeholder":"Enter Weight"},model:{value:(seafood.weight),callback:function ($$v) {_vm.$set(seafood, "weight", $$v)},expression:"seafood.weight"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Rate per Kg ($)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input_background",attrs:{"placeholder":"Enter Rate"},on:{"input":function($event){return _vm.changeAmount(seafood.price_rate, seafood.weight, _vm.index)}},model:{value:(seafood.price_rate),callback:function ($$v) {_vm.$set(seafood, "price_rate", $$v)},expression:"seafood.price_rate"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Total cost ($)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input_background",attrs:{"placeholder":"Enter Total cost"},model:{value:(seafood.total_amount),callback:function ($$v) {_vm.$set(seafood, "total_amount", $$v)},expression:"seafood.total_amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)})],2)],1),_c('div',{staticClass:"d-flex justify-content-end pt-3"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"form_submit_button",attrs:{"variant":"none"}},[_c('span',{staticClass:"button_text_styles",on:{"click":function($event){return _vm.updateCustomInvoice()}}},[_vm._v("Save")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }